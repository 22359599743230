import React from 'react'
import Img from 'gatsby-image'
import { H2 } from '../Headings'
import tw from 'twin.macro'
import { ButtonLink } from '../Button'
import { ALink } from '../A'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import MetaContent from './MetaContent'
import Logos from '../Logos'

const PostExcerpt = props => {
  const { title, date, categories, featuredImage, excerpt, slug } = props
  return (
    <article {...props} aria-label={title}>
      <ALink tw="no-underline hover:(underline)" to={`/learn/blog/${slug}`}>
        <H2 tw="mb-0">{title}</H2>
      </ALink>
      <MetaContent
        tw="mb-4"
        publishedDate={date}
        categories={categories.nodes}
      />
      <div css={[tw`bg-gray-50 border border-gray-100 w-full relative mb-4`]}>
        <ALink to={`/learn/blog/${slug}`}>
          {featuredImage?.node ? (
            <>
              {featuredImage?.node?.localFile?.childImageSharp?.fluid ? (
                <Img
                  tw="rounded-lg"
                  fluid={featuredImage?.node?.localFile?.childImageSharp?.fluid}
                  alt={'Image showing ' + title}
                  loading="lazy"
                />
              ) : (
                <img src={featuredImage?.node?.sourceUrl} loading="lazy" />
              )}
            </>
          ) : (
            <div css={['padding-bottom:56.25%;']}>
              <Logos.WithCookie
                css={[
                  'width: 10em; top:50%; left:50%;',
                  tw`absolute transform -translate-x-1/2 -translate-y-1/2`,
                ]}
              />
            </div>
          )}
        </ALink>
      </div>
      <div
        tw="mb-4 text-gray-700"
        dangerouslySetInnerHTML={{ __html: excerpt }}
      />
      <ButtonLink to={`/learn/blog/${slug}`} large>
        <span tw="sr-only">Read {title}</span>
        Read More <FontAwesomeIcon icon={faChevronRight} tw="ml-8" />
      </ButtonLink>
    </article>
  )
}

export default PostExcerpt
