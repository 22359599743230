import React from 'react'
import { dateFormat } from '../../helpers'
import 'twin.macro'
import A from '../A'

const MetaContent = ({ publishedDate, categories }) => (
  <div tw="mb-4 text-gray-700">
    <time>{dateFormat(publishedDate)}</time>

    {Array.isArray(categories) && categories.length ? (
      <span tw="ml-4">
        Published In:{' '}
        {categories.map(({ slug, name }, index) => {
          const last = index === categories.length - 1
          return (
            <span key={slug}>
              <A href={`/learn/blog/category/${slug}`}>{name}</A>
              {!last && ', '}
            </span>
          )
        })}
      </span>
    ) : null}
  </div>
)

export default MetaContent
